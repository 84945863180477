import React from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Layout from '../layouts';

import styles from './404.module.scss';

const FourOFour = () => (
  <Layout
    metaTitle="404!"
    metaDescription="Can’t find that page sorry"
  >
    <div className={styles.Container}>
      <h1>Can’t find that page sorry</h1>
      <p>Click <AnchorLink to="/" title="jdlp.co.uk">here</AnchorLink> to go home</p>
    </div>
  </Layout>
);

export default FourOFour;
